import { atom } from "recoil";

import { globalKey } from "../util/key";

const storageKey = "api_key";
export const apiKeyAtom = atom<string | undefined>({
  key: globalKey("api_key"),
  default: localStorage.getItem(storageKey) ?? undefined,
  effects_UNSTABLE: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          localStorage.setItem(storageKey, newValue);
        } else {
          localStorage.removeItem(storageKey);
        }
      }),
  ],
});
