import axios, { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { selector, useRecoilValue } from "recoil";

import { Configuration, DefaultApiFactory } from "../../generated-api-client";
import {
  accessTokenIatSelector,
  accessTokenResolverForCurrentUser,
} from "../auth/auth.atom";
import { globalKey } from "../util/key";
import { apiBasePath } from "./api-base-path";
import { apiKeyAtom } from "./apiKey.atom";

const createAxiosConfig = (apiKey: string | undefined): AxiosRequestConfig => {
  if (apiKey) {
    return { headers: { "X-Api-Key": apiKey } };
  }
  return {};
};

export const useApiWithAuth = () => {
  const apiKey = useRecoilValue(apiKeyAtom);
  const accessTokenResolver = useRecoilValue(accessTokenResolverForCurrentUser);
  const accessTokenIat = useRecoilValue(accessTokenIatSelector);
  const history = useHistory();
  const instance = axios.create();
  instance.interceptors.request.use((c) => {
    const config = c;
    const lastLoggedOutAt = localStorage.getItem("last_logged_out_at") ?? "";
    if (c && accessTokenIat < lastLoggedOutAt) {
      config.headers!.Authorization = "";
      localStorage.setItem("logged_out", "true");
    }
    return config;
  });
  instance.interceptors.response.use(
    (r) => r,
    (err) => {
      if (err && err.response && err.response.status === 401) {
        history.replace("/");
      }
      return Promise.reject(err);
    }
  );

  const api = useMemo(() => {
    return DefaultApiFactory(
      new Configuration({
        accessToken: () => {
          return accessTokenResolver()!;
        },
        baseOptions: createAxiosConfig(apiKey),
      }),
      apiBasePath,
      instance
    );
  }, [accessTokenResolver]);

  return api;
};

export const useApiWithoutAuth = () => {
  const apiKey = useRecoilValue(apiKeyAtom);
  const api = useMemo(() => {
    return DefaultApiFactory(
      new Configuration({ baseOptions: createAxiosConfig(apiKey) }),
      apiBasePath
    );
  }, []);
  return api;
};

export const apiClientSelector = selector<ApiClientType>({
  key: globalKey("api", "auth"),
  get: ({ get }) => {
    const apiKey = get(apiKeyAtom);
    const accessTokenResolver = get(accessTokenResolverForCurrentUser);
    return DefaultApiFactory(
      new Configuration({
        accessToken: () => {
          return accessTokenResolver()!;
        },
        baseOptions: createAxiosConfig(apiKey),
      }),
      apiBasePath
    );
  },
});

export type ApiClientType = ReturnType<typeof DefaultApiFactory>;
export const getAxiosErrorMessage = (e: unknown): string => {
  if (axios.isAxiosError(e)) {
    if (e.response?.data?.message) {
      return e.response.data.message;
    }
  }
  return "";
};
