/* tslint:disable */
/* eslint-disable */
/**
 * ShepherdHR API
 * The ShepherdHR API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionDefinition
 */
export interface ActionDefinition {
    /**
     * 
     * @type {string}
     * @memberof ActionDefinition
     */
    'action_label': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDefinition
     */
    'action_name': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDefinition
     */
    'action_type': ActionDefinitionActionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ActionDefinitionActionTypeEnum {
    Approve = 'approve',
    Disapprove = 'disapprove',
    Primary = 'primary',
    PrimaryOutline = 'primary_outline'
}

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'article_identifier': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'check_status': ArticleCheckStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'csv_identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'last_commented_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'last_update_token': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'latest_hash': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'latest_version': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'latest_version_at': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'memo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Article
     */
    'requested_reviewers'?: Array<string>;
    /**
     * 
     * @type {Array<Reviewer>}
     * @memberof Article
     */
    'reviewers'?: Array<Reviewer>;
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof Article
     */
    'site_article': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'site_article_custom_name': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'site_article_id': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'title': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ArticleCheckStatusEnum {
    Ok = 'ok',
    HasInfo = 'has_info',
    HasWarning = 'has_warning',
    HasError = 'has_error'
}

/**
 * 
 * @export
 * @interface ArticleBriefCustom
 */
export interface ArticleBriefCustom {
    /**
     * 
     * @type {string}
     * @memberof ArticleBriefCustom
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleBriefCustom
     */
    'keys': Array<string>;
}
/**
 * 
 * @export
 * @interface ArticleBriefDefinition
 */
export interface ArticleBriefDefinition {
    /**
     * 
     * @type {Array<ArticleBriefCustom>}
     * @memberof ArticleBriefDefinition
     */
    'customs': Array<ArticleBriefCustom>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleBriefDefinition
     */
    'labels': Array<string>;
}
/**
 * 
 * @export
 * @interface ArticleComment
 */
export interface ArticleComment {
    /**
     * 
     * @type {string}
     * @memberof ArticleComment
     */
    'article_identifier': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleComment
     */
    'commented_at': string;
    /**
     * ソート以外の目的で使用しない。日時/ユーザーID
     * @type {string}
     * @memberof ArticleComment
     */
    'sort_key': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleComment
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleComment
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface ArticleVersion
 */
export interface ArticleVersion {
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticleVersion
     */
    'article': Array<UserEditableKeyValue>;
    /**
     * articleの型情報名
     * @type {string}
     * @memberof ArticleVersion
     */
    'article_custom_name': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'article_hash': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'article_identifier': string;
    /**
     * 
     * @type {Array<RuleCheckComment>}
     * @memberof ArticleVersion
     */
    'check_result': Array<RuleCheckComment>;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'check_status': ArticleVersionCheckStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'csv_identifier'?: string;
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticleVersion
     */
    'estimated_values': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'estimated_values_custom_name': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'estimated_values_updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'last_update_token': string;
    /**
     * 
     * @type {Array<NgWordComment>}
     * @memberof ArticleVersion
     */
    'ng_words'?: Array<NgWordComment>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleVersion
     */
    'requested_reviewers'?: Array<string>;
    /**
     * 
     * @type {Array<Reviewer>}
     * @memberof ArticleVersion
     */
    'reviewers'?: Array<Reviewer>;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'site_article_id': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleVersion
     */
    'updated_at': string;
    /**
     * バージョンIDが入る。バージョンIDは日時順に並ぶよう工夫されており、問題なく表示が可能。
     * @type {string}
     * @memberof ArticleVersion
     */
    'version': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ArticleVersionCheckStatusEnum {
    Ok = 'ok',
    HasInfo = 'has_info',
    HasWarning = 'has_warning',
    HasError = 'has_error'
}

/**
 * 
 * @export
 * @interface ArticlesActionsResponse
 */
export interface ArticlesActionsResponse {
    /**
     * 
     * @type {Array<ActionDefinition>}
     * @memberof ArticlesActionsResponse
     */
    'actions': Array<ActionDefinition>;
}
/**
 * 
 * @export
 * @interface ArticlesBatchGetResponse
 */
export interface ArticlesBatchGetResponse {
    /**
     * リクエストidsの順に並んだArticleが格納される。存在しない/ログインしているユーザーから読めないArticleは省略される
     * @type {Array<Article>}
     * @memberof ArticlesBatchGetResponse
     */
    'articles': Array<Article>;
}
/**
 * 
 * @export
 * @interface ArticlesCheckRequest
 */
export interface ArticlesCheckRequest {
    /**
     * 任意のarticle
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesCheckRequest
     */
    'article': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCheckRequest
     */
    'article_custom_name': string;
}
/**
 * 
 * @export
 * @interface ArticlesCheckResponse
 */
export interface ArticlesCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof ArticlesCheckResponse
     */
    'custom_name': string;
    /**
     * 
     * @type {Array<NgWordComment>}
     * @memberof ArticlesCheckResponse
     */
    'ng_words'?: Array<NgWordComment>;
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesCheckResponse
     */
    'parse_result': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {Array<RuleCheckComment>}
     * @memberof ArticlesCheckResponse
     */
    'rule_check_result': Array<RuleCheckComment>;
}
/**
 * 
 * @export
 * @interface ArticlesCommentPutRequest
 */
export interface ArticlesCommentPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticlesCommentPutRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ArticlesCreateRequest
 */
export interface ArticlesCreateRequest {
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesCreateRequest
     */
    'article': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCreateRequest
     */
    'article_custom_name': string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCreateRequest
     */
    'article_id': string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCreateRequest
     */
    'article_title': string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCreateRequest
     */
    'csv_identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesCreateRequest
     */
    'site_name': string;
}
/**
 * 
 * @export
 * @interface ArticlesCreateResponse
 */
export interface ArticlesCreateResponse {
    /**
     * 
     * @type {Article}
     * @memberof ArticlesCreateResponse
     */
    'article': Article;
    /**
     * 
     * @type {ArticleVersion}
     * @memberof ArticlesCreateResponse
     */
    'version': ArticleVersion;
}
/**
 * 
 * @export
 * @interface ArticlesListIdsRequest
 */
export interface ArticlesListIdsRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticlesListIdsRequest
     */
    'csv_identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_ai_commented_error'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_created_by_me'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_incomplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_needs_review_by_me'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_not_approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_not_reviewed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesListIdsRequest
     */
    'only_reviewd_by_me'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlesListIdsRequest
     */
    'since'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesListIdsRequest
     */
    'until'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesListIdsResponse
 */
export interface ArticlesListIdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlesListIdsResponse
     */
    'article_identifiers': Array<string>;
}
/**
 * 
 * @export
 * @interface ArticlesMemoUpdateRequest
 */
export interface ArticlesMemoUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticlesMemoUpdateRequest
     */
    'memo': string;
}
/**
 * 
 * @export
 * @interface ArticlesNewVersionRequest
 */
export interface ArticlesNewVersionRequest {
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesNewVersionRequest
     */
    'article_values': Array<UserEditableKeyValue>;
    /**
     * 
     * @type {FrontCheckResult}
     * @memberof ArticlesNewVersionRequest
     */
    'check_result': FrontCheckResult;
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesNewVersionRequest
     */
    'estimated_values': Array<UserEditableKeyValue>;
}
/**
 * 
 * @export
 * @interface ArticlesValidateRequest
 */
export interface ArticlesValidateRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticlesValidateRequest
     */
    'custom_name': string;
    /**
     * 
     * @type {Array<UserEditableKeyValue>}
     * @memberof ArticlesValidateRequest
     */
    'values': Array<UserEditableKeyValue>;
}
/**
 * 
 * @export
 * @interface ArticlesValidateResponse
 */
export interface ArticlesValidateResponse {
    /**
     * 
     * @type {Array<RuleCheckComment>}
     * @memberof ArticlesValidateResponse
     */
    'rule_check_result': Array<RuleCheckComment>;
}
/**
 * 
 * @export
 * @interface AuthAccessTokenRequest
 */
export interface AuthAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthAccessTokenRequest
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface AuthAccessTokenResponse
 */
export interface AuthAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthAccessTokenResponse
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthLoginRequest
     */
    'long_lived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthLoginResponse
 */
export interface AuthLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginResponse
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginResponse
     */
    'refresh_token_expires': string;
}
/**
 * 
 * @export
 * @interface ChoiceDefinition
 */
export interface ChoiceDefinition {
    /**
     * 
     * @type {string}
     * @memberof ChoiceDefinition
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ChoiceDefinition
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CompaniesGetRolesResponse
 */
export interface CompaniesGetRolesResponse {
    /**
     * 
     * @type {object}
     * @memberof CompaniesGetRolesResponse
     */
    'roles': object;
}
/**
 * 
 * @export
 * @interface CompaniesPutDepartmentsRequest
 */
export interface CompaniesPutDepartmentsRequest {
    /**
     * 
     * @type {Array<CompaniesPutDepartmentsRequestDepartment>}
     * @memberof CompaniesPutDepartmentsRequest
     */
    'departments': Array<CompaniesPutDepartmentsRequestDepartment>;
}
/**
 * 
 * @export
 * @interface CompaniesPutDepartmentsRequestDepartment
 */
export interface CompaniesPutDepartmentsRequestDepartment {
    /**
     * nullの場合はIDを自動生成
     * @type {string}
     * @memberof CompaniesPutDepartmentsRequestDepartment
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPutDepartmentsRequestDepartment
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'company_id': string;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Company
     */
    'departments': Array<Department>;
    /**
     * 実契約上の会社名。ユーザー情報に表示される会社名
     * @type {string}
     * @memberof Company
     */
    'legal_name'?: string;
    /**
     * 表示名 通常は会社名と同一。front左上に媒体名を表示したい場合には、ここに媒体名を入れる
     * @type {string}
     * @memberof Company
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CompanyFeatures
 */
export interface CompanyFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFeatures
     */
    'useChecker': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFeatures
     */
    'useReviewer': boolean;
}
/**
 * 
 * @export
 * @interface CsvFile
 */
export interface CsvFile {
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'csv_identifier': string;
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'upload_status': CsvFileUploadStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CsvFile
     */
    'user_id': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CsvFileUploadStatusEnum {
    Completed = 'completed',
    InProgress = 'in_progress',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface CsvFilesBatchGetResponse
 */
export interface CsvFilesBatchGetResponse {
    /**
     * 
     * @type {Array<CsvFile>}
     * @memberof CsvFilesBatchGetResponse
     */
    'csv_files': Array<CsvFile>;
}
/**
 * 
 * @export
 * @interface CsvFilesCreateRequest
 */
export interface CsvFilesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvFilesCreateRequest
     */
    'file_name': string;
}
/**
 * 
 * @export
 * @interface CsvFilesIdsResponse
 */
export interface CsvFilesIdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CsvFilesIdsResponse
     */
    'csv_identifiers': Array<string>;
}
/**
 * 
 * @export
 * @interface CsvFilesListArticlesIdsRequest
 */
export interface CsvFilesListArticlesIdsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CsvFilesListArticlesIdsRequest
     */
    'only_ai_commented_error'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CsvFilesListArticlesIdsRequest
     */
    'only_incomplete'?: boolean;
}
/**
 * 
 * @export
 * @interface CsvFilesListArticlesIdsResponse
 */
export interface CsvFilesListArticlesIdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CsvFilesListArticlesIdsResponse
     */
    'article_identifiers': Array<string>;
}
/**
 * 
 * @export
 * @interface CsvFilesListRequest
 */
export interface CsvFilesListRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvFilesListRequest
     */
    'since'?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvFilesListRequest
     */
    'until'?: string;
}
/**
 * 
 * @export
 * @interface CsvFilesUpdateRequest
 */
export interface CsvFilesUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvFilesUpdateRequest
     */
    'file_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvFilesUpdateRequest
     */
    'upload_status'?: CsvFilesUpdateRequestUploadStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CsvFilesUpdateRequestUploadStatusEnum {
    Completed = 'completed',
    InProgress = 'in_progress',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface DataDefinition
 */
export interface DataDefinition {
    /**
     * 
     * @type {string}
     * @memberof DataDefinition
     */
    'custom_name': string;
    /**
     * 
     * @type {Array<ValueDefinitionString | ValueDefinitionChoice | ValueDefinitionInteger | ValueDefinitionFloat | ValueDefinitionBitSet | ValueDefinitionBoolean>}
     * @memberof DataDefinition
     */
    'data': Array<ValueDefinitionString | ValueDefinitionChoice | ValueDefinitionInteger | ValueDefinitionFloat | ValueDefinitionBitSet | ValueDefinitionBoolean>;
    /**
     * 
     * @type {string}
     * @memberof DataDefinition
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof DataDefinition
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FormCondition
 */
export interface FormCondition {
    /**
     * 
     * @type {string}
     * @memberof FormCondition
     */
    'condition': FormConditionConditionEnum;
    /**
     * 
     * @type {string}
     * @memberof FormCondition
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof FormCondition
     */
    'reference_value'?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum FormConditionConditionEnum {
    Equal = 'equal'
}

/**
 * 
 * @export
 * @interface FormDefinition
 */
export interface FormDefinition {
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    'custom_name': string;
    /**
     * 
     * @type {Array<FormRowDefinition>}
     * @memberof FormDefinition
     */
    'form': Array<FormRowDefinition>;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface FormRowDefinition
 */
export interface FormRowDefinition {
    /**
     * 
     * @type {number}
     * @memberof FormRowDefinition
     */
    'number_of_decimals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormRowDefinition
     */
    'prefferd_presentation'?: FormRowDefinitionPrefferdPresentationEnum;
    /**
     * 
     * @type {Array<FormCondition>}
     * @memberof FormRowDefinition
     */
    'rewritable_conditions'?: Array<FormCondition>;
    /**
     * 
     * @type {string}
     * @memberof FormRowDefinition
     */
    'rounding_policy'?: FormRowDefinitionRoundingPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof FormRowDefinition
     */
    'target': string;
    /**
     * 
     * @type {boolean}
     * @memberof FormRowDefinition
     */
    'visible'?: boolean;
    /**
     * 
     * @type {Array<FormCondition>}
     * @memberof FormRowDefinition
     */
    'visible_conditions'?: Array<FormCondition>;
}

/**
    * @export
    * @enum {string}
    */
export enum FormRowDefinitionPrefferdPresentationEnum {
    Radio = 'radio'
}
/**
    * @export
    * @enum {string}
    */
export enum FormRowDefinitionRoundingPolicyEnum {
    Round = 'round'
}

/**
 * 
 * @export
 * @interface FrontCheckResult
 */
export interface FrontCheckResult {
    /**
     * 
     * @type {Array<NgWordComment>}
     * @memberof FrontCheckResult
     */
    'ng_words'?: Array<NgWordComment>;
    /**
     * 
     * @type {Array<RuleCheckComment>}
     * @memberof FrontCheckResult
     */
    'rule_check_result': Array<RuleCheckComment>;
}
/**
 * 
 * @export
 * @interface NgWordComment
 */
export interface NgWordComment {
    /**
     * 
     * @type {string}
     * @memberof NgWordComment
     */
    'level': NgWordCommentLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof NgWordComment
     */
    'message': string;
    /**
     * 
     * @type {Array<TextSpan>}
     * @memberof NgWordComment
     */
    'spans': Array<TextSpan>;
    /**
     * 
     * @type {string}
     * @memberof NgWordComment
     */
    'target': string;
}

/**
    * @export
    * @enum {string}
    */
export enum NgWordCommentLevelEnum {
    Error = 'error',
    Warning = 'warning',
    Info = 'info'
}

/**
 * 
 * @export
 * @interface Reviewer
 */
export interface Reviewer {
    /**
     * 
     * @type {boolean}
     * @memberof Reviewer
     */
    'reviewed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    'reviewed_at': string;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface RuleCheckComment
 */
export interface RuleCheckComment {
    /**
     * 
     * @type {string}
     * @memberof RuleCheckComment
     */
    'level': RuleCheckCommentLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof RuleCheckComment
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof RuleCheckComment
     */
    'target': string;
}

/**
    * @export
    * @enum {string}
    */
export enum RuleCheckCommentLevelEnum {
    Error = 'error',
    Warning = 'warning',
    Info = 'info'
}

/**
 * 
 * @export
 * @interface TextSpan
 */
export interface TextSpan {
    /**
     * 
     * @type {number}
     * @memberof TextSpan
     */
    'end': number;
    /**
     * 
     * @type {number}
     * @memberof TextSpan
     */
    'start': number;
    /**
     * 
     * @type {string}
     * @memberof TextSpan
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface UserEditableKeyValue
 */
export interface UserEditableKeyValue {
    /**
     * 
     * @type {string}
     * @memberof UserEditableKeyValue
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditableKeyValue
     */
    'user_input'?: boolean;
    /**
     * 
     * @type {string | number | Array<string> | Array<number> | boolean | object}
     * @memberof UserEditableKeyValue
     */
    'value': string | number | Array<string> | Array<number> | boolean | object | null;
}
/**
 * 
 * @export
 * @interface UsersBatchDeleteRequest
 */
export interface UsersBatchDeleteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersBatchDeleteRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface UsersBatchGetResponse
 */
export interface UsersBatchGetResponse {
    /**
     * 
     * @type {Array<UsersModel>}
     * @memberof UsersBatchGetResponse
     */
    'users': Array<UsersModel>;
}
/**
 * 
 * @export
 * @interface UsersCreateDto
 */
export interface UsersCreateDto {
    /**
     * 
     * @type {boolean}
     * @memberof UsersCreateDto
     */
    'admin': boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateDto
     */
    'department_id': string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateDto
     */
    'password'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersCreateDto
     */
    'request_reviewers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateDto
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UsersIdsResponse
 */
export interface UsersIdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersIdsResponse
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface UsersModel
 */
export interface UsersModel {
    /**
     * 
     * @type {boolean}
     * @memberof UsersModel
     */
    'admin': boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'department_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersModel
     */
    'request_reviewers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof UsersModel
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface UsersPatchRequest
 */
export interface UsersPatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UsersPatchRequest
     */
    'admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersPatchRequest
     */
    'department_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersPatchRequest
     */
    'request_reviewers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsersPatchRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UsersPatchSelfRequest
 */
export interface UsersPatchSelfRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersPatchSelfRequest
     */
    'department_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersPatchSelfRequest
     */
    'request_reviewers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsersPatchSelfRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UsersResetPasswordRequest
 */
export interface UsersResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersResetPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ValidationDefinitionNumberRange
 */
export interface ValidationDefinitionNumberRange {
    /**
     * 
     * @type {number}
     * @memberof ValidationDefinitionNumberRange
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationDefinitionNumberRange
     */
    'min'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValidationDefinitionNumberRange
     */
    'validator': string;
}
/**
 * 
 * @export
 * @interface ValidationDefinitionStringRegex
 */
export interface ValidationDefinitionStringRegex {
    /**
     * 
     * @type {string}
     * @memberof ValidationDefinitionStringRegex
     */
    'regex': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationDefinitionStringRegex
     */
    'validator': string;
}
/**
 * 
 * @export
 * @interface ValueDefinitionBitSet
 */
export interface ValueDefinitionBitSet {
    /**
     * 
     * @type {Array<ValueDefinitionSetElement>}
     * @memberof ValueDefinitionBitSet
     */
    'bits': Array<ValueDefinitionSetElement>;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBitSet
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBitSet
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionBitSet
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ValueDefinitionBitSet
     */
    'placeholder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBitSet
     */
    'type': ValueDefinitionBitSetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBitSet
     */
    'unit'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionBitSetTypeEnum {
    Bitset = 'bitset'
}

/**
 * 
 * @export
 * @interface ValueDefinitionBoolean
 */
export interface ValueDefinitionBoolean {
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'falseMean'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionBoolean
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionBoolean
     */
    'placeholder'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'trueMean'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'type': ValueDefinitionBooleanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionBoolean
     */
    'unit'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionBooleanTypeEnum {
    Boolean = 'boolean'
}

/**
 * 
 * @export
 * @interface ValueDefinitionChoice
 */
export interface ValueDefinitionChoice {
    /**
     * 
     * @type {Array<ChoiceDefinition>}
     * @memberof ValueDefinitionChoice
     */
    'choices': Array<ChoiceDefinition>;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionChoice
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionChoice
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionChoice
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionChoice
     */
    'placeholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionChoice
     */
    'type': ValueDefinitionChoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionChoice
     */
    'unit'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionChoiceTypeEnum {
    Choice = 'choice'
}

/**
 * 
 * @export
 * @interface ValueDefinitionFloat
 */
export interface ValueDefinitionFloat {
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionFloat
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionFloat
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionFloat
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ValueDefinitionFloat
     */
    'placeholder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionFloat
     */
    'type': ValueDefinitionFloatTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionFloat
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<ValidationDefinitionNumberRange>}
     * @memberof ValueDefinitionFloat
     */
    'validations'?: Array<ValidationDefinitionNumberRange>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionFloatTypeEnum {
    Float = 'float'
}

/**
 * 
 * @export
 * @interface ValueDefinitionInteger
 */
export interface ValueDefinitionInteger {
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionInteger
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionInteger
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionInteger
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ValueDefinitionInteger
     */
    'placeholder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionInteger
     */
    'type': ValueDefinitionIntegerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionInteger
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<ValidationDefinitionNumberRange>}
     * @memberof ValueDefinitionInteger
     */
    'validations'?: Array<ValidationDefinitionNumberRange>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionIntegerTypeEnum {
    Integer = 'integer'
}

/**
 * 
 * @export
 * @interface ValueDefinitionSetElement
 */
export interface ValueDefinitionSetElement {
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionSetElement
     */
    'bit_key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionSetElement
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface ValueDefinitionString
 */
export interface ValueDefinitionString {
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionString
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionString
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionString
     */
    'multiline'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDefinitionString
     */
    'nullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionString
     */
    'placeholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionString
     */
    'type': ValueDefinitionStringTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValueDefinitionString
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<ValidationDefinitionStringRegex>}
     * @memberof ValueDefinitionString
     */
    'validations'?: Array<ValidationDefinitionStringRegex>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueDefinitionStringTypeEnum {
    String = 'string'
}

/**
 * 
 * @export
 * @interface WsAssociateRequest
 */
export interface WsAssociateRequest {
    /**
     * 
     * @type {string}
     * @memberof WsAssociateRequest
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof WsAssociateRequest
     */
    'token': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerApprove: async (article: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerApprove', 'article', article)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('articlesControllerApprove', 'version', version)
            const localVarPath = `/articles/{article}/{version}/approve`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerBatchGetArticles: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('articlesControllerBatchGetArticles', 'ids', ids)
            const localVarPath = `/articles/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerBatchGetComments: async (article: string, ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerBatchGetComments', 'article', article)
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('articlesControllerBatchGetComments', 'ids', ids)
            const localVarPath = `/articles/{article}/comments/batch`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チェック (推定+検査validate) のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesCheckRequest} articlesCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCheck: async (article: string, articlesCheckRequest: ArticlesCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerCheck', 'article', article)
            // verify required parameter 'articlesCheckRequest' is not null or undefined
            assertParamExists('articlesControllerCheck', 'articlesCheckRequest', articlesCheckRequest)
            const localVarPath = `/articles/{article}/check`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesCommentPutRequest} articlesCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerComment: async (article: string, articlesCommentPutRequest: ArticlesCommentPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerComment', 'article', article)
            // verify required parameter 'articlesCommentPutRequest' is not null or undefined
            assertParamExists('articlesControllerComment', 'articlesCommentPutRequest', articlesCommentPutRequest)
            const localVarPath = `/articles/{article}/comments`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesCommentPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate: async (articlesCreateRequest: ArticlesCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articlesCreateRequest' is not null or undefined
            assertParamExists('articlesControllerCreate', 'articlesCreateRequest', articlesCreateRequest)
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerDeleteArticle: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articlesControllerDeleteArticle', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerDisapprove: async (article: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerDisapprove', 'article', article)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('articlesControllerDisapprove', 'version', version)
            const localVarPath = `/articles/{article}/{version}/approve`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetActions: async (article: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetActions', 'article', article)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('articlesControllerGetActions', 'version', version)
            const localVarPath = `/articles/{article}/{version}/actions`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticle: async (article: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetArticle', 'article', article)
            const localVarPath = `/articles/{article}`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticleFromSiteId: async (site: string, article: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('articlesControllerGetArticleFromSiteId', 'site', site)
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetArticleFromSiteId', 'article', article)
            const localVarPath = `/articles/v2/{site}/{article}`
                .replace(`{${"site"}}`, encodeURIComponent(String(site)))
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticleVersions: async (article: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetArticleVersions', 'article', article)
            const localVarPath = `/articles/{article}/versions`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetLatestArticle: async (site: string, article: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('articlesControllerGetLatestArticle', 'site', site)
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetLatestArticle', 'article', article)
            const localVarPath = `/articles/v2/{site}/{article}/latest`
                .replace(`{${"site"}}`, encodeURIComponent(String(site)))
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetVersion: async (article: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerGetVersion', 'article', article)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('articlesControllerGetVersion', 'version', version)
            const localVarPath = `/articles/{article}/{version}`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArticlesListIdsRequest} articlesListIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerListArticleIds: async (articlesListIdsRequest: ArticlesListIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articlesListIdsRequest' is not null or undefined
            assertParamExists('articlesControllerListArticleIds', 'articlesListIdsRequest', articlesListIdsRequest)
            const localVarPath = `/articles/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesListIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerListCommentIds: async (article: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerListCommentIds', 'article', article)
            const localVarPath = `/articles/{article}/comments/ids`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesNewVersionRequest} articlesNewVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateArticleAndVersion: async (article: string, articlesNewVersionRequest: ArticlesNewVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerUpdateArticleAndVersion', 'article', article)
            // verify required parameter 'articlesNewVersionRequest' is not null or undefined
            assertParamExists('articlesControllerUpdateArticleAndVersion', 'articlesNewVersionRequest', articlesNewVersionRequest)
            const localVarPath = `/articles/{article}`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesNewVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateEstimatedValues: async (article: string, version: string, articlesValidateRequest: ArticlesValidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerUpdateEstimatedValues', 'article', article)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('articlesControllerUpdateEstimatedValues', 'version', version)
            // verify required parameter 'articlesValidateRequest' is not null or undefined
            assertParamExists('articlesControllerUpdateEstimatedValues', 'articlesValidateRequest', articlesValidateRequest)
            const localVarPath = `/articles/{article}/{version}/estimated_values`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesValidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesMemoUpdateRequest} articlesMemoUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateMemo: async (article: string, articlesMemoUpdateRequest: ArticlesMemoUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerUpdateMemo', 'article', article)
            // verify required parameter 'articlesMemoUpdateRequest' is not null or undefined
            assertParamExists('articlesControllerUpdateMemo', 'articlesMemoUpdateRequest', articlesMemoUpdateRequest)
            const localVarPath = `/articles/{article}/memo`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesMemoUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 値が法に従っているかの検査のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerValidate: async (article: string, articlesValidateRequest: ArticlesValidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('articlesControllerValidate', 'article', article)
            // verify required parameter 'articlesValidateRequest' is not null or undefined
            assertParamExists('articlesControllerValidate', 'articlesValidateRequest', articlesValidateRequest)
            const localVarPath = `/articles/{article}/validate`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesValidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerIssueAccessToken: async (authAccessTokenRequest: AuthAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAccessTokenRequest' is not null or undefined
            assertParamExists('authControllerIssueAccessToken', 'authAccessTokenRequest', authAccessTokenRequest)
            const localVarPath = `/auth/access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (authLoginRequest: AuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authLoginRequest' is not null or undefined
            assertParamExists('authControllerLogin', 'authLoginRequest', authLoginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerGet', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetFeatures: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerGetFeatures', 'id', id)
            const localVarPath = `/companies/{id}/features`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetRoles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerGetRoles', 'id', id)
            const localVarPath = `/companies/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelfFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/self/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelfRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/self/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateDepartments: async (id: string, companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerUpdateDepartments', 'id', id)
            // verify required parameter 'companiesPutDepartmentsRequest' is not null or undefined
            assertParamExists('companiesControllerUpdateDepartments', 'companiesPutDepartmentsRequest', companiesPutDepartmentsRequest)
            const localVarPath = `/companies/{id}/departments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companiesPutDepartmentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateSelfDepartments: async (companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companiesPutDepartmentsRequest' is not null or undefined
            assertParamExists('companiesControllerUpdateSelfDepartments', 'companiesPutDepartmentsRequest', companiesPutDepartmentsRequest)
            const localVarPath = `/companies/self/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companiesPutDepartmentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerBatchGetCsvFiles: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('csvFilesControllerBatchGetCsvFiles', 'ids', ids)
            const localVarPath = `/csv-files/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CsvFilesCreateRequest} csvFilesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerCreate: async (csvFilesCreateRequest: CsvFilesCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvFilesCreateRequest' is not null or undefined
            assertParamExists('csvFilesControllerCreate', 'csvFilesCreateRequest', csvFilesCreateRequest)
            const localVarPath = `/csv-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvFilesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerDeleteCsvFile: async (csvfile: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvfile' is not null or undefined
            assertParamExists('csvFilesControllerDeleteCsvFile', 'csvfile', csvfile)
            const localVarPath = `/csv-files/{csvfile}`
                .replace(`{${"csvfile"}}`, encodeURIComponent(String(csvfile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerGetCsvFile: async (csvfile: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvfile' is not null or undefined
            assertParamExists('csvFilesControllerGetCsvFile', 'csvfile', csvfile)
            const localVarPath = `/csv-files/{csvfile}`
                .replace(`{${"csvfile"}}`, encodeURIComponent(String(csvfile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} csvIdentifier 
         * @param {CsvFilesListArticlesIdsRequest} csvFilesListArticlesIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerListCsvArticlesIds: async (csvIdentifier: string, csvFilesListArticlesIdsRequest: CsvFilesListArticlesIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvIdentifier' is not null or undefined
            assertParamExists('csvFilesControllerListCsvArticlesIds', 'csvIdentifier', csvIdentifier)
            // verify required parameter 'csvFilesListArticlesIdsRequest' is not null or undefined
            assertParamExists('csvFilesControllerListCsvArticlesIds', 'csvFilesListArticlesIdsRequest', csvFilesListArticlesIdsRequest)
            const localVarPath = `/csv-files/{csv_identifier}/ids`
                .replace(`{${"csv_identifier"}}`, encodeURIComponent(String(csvIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvFilesListArticlesIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CsvFilesListRequest} csvFilesListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerListCsvFileIds: async (csvFilesListRequest: CsvFilesListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvFilesListRequest' is not null or undefined
            assertParamExists('csvFilesControllerListCsvFileIds', 'csvFilesListRequest', csvFilesListRequest)
            const localVarPath = `/csv-files/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvFilesListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {CsvFilesUpdateRequest} csvFilesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerUpdateCsvFile: async (csvfile: string, csvFilesUpdateRequest: CsvFilesUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvfile' is not null or undefined
            assertParamExists('csvFilesControllerUpdateCsvFile', 'csvfile', csvfile)
            // verify required parameter 'csvFilesUpdateRequest' is not null or undefined
            assertParamExists('csvFilesControllerUpdateCsvFile', 'csvFilesUpdateRequest', csvFilesUpdateRequest)
            const localVarPath = `/csv-files/{csvfile}`
                .replace(`{${"csvfile"}}`, encodeURIComponent(String(csvfile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvFilesUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetArticleBriefDefinition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customs/articles/brief`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetArticleDefinition: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('customsControllerGetArticleDefinition', 'name', name)
            const localVarPath = `/customs/articles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetDataDefinition: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('customsControllerGetDataDefinition', 'name', name)
            const localVarPath = `/customs/data/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetEstimatedValuesDefinition: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('customsControllerGetEstimatedValuesDefinition', 'name', name)
            const localVarPath = `/customs/estimated/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerCreate: async (articlesCreateRequest: ArticlesCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articlesCreateRequest' is not null or undefined
            assertParamExists('headlessApiControllerCreate', 'articlesCreateRequest', articlesCreateRequest)
            const localVarPath = `/headless-api/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/headless-api/hello`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerValidate: async (article: string, articlesValidateRequest: ArticlesValidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('headlessApiControllerValidate', 'article', article)
            // verify required parameter 'articlesValidateRequest' is not null or undefined
            assertParamExists('headlessApiControllerValidate', 'articlesValidateRequest', articlesValidateRequest)
            const localVarPath = `/headless-api/{article}/validate`
                .replace(`{${"article"}}`, encodeURIComponent(String(article)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesValidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsersBatchDeleteRequest} usersBatchDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerBatchDelete: async (usersBatchDeleteRequest: UsersBatchDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersBatchDeleteRequest' is not null or undefined
            assertParamExists('usersControllerBatchDelete', 'usersBatchDeleteRequest', usersBatchDeleteRequest)
            const localVarPath = `/users/batch_delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersBatchDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerBatchGet: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('usersControllerBatchGet', 'ids', ids)
            const localVarPath = `/users/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsersCreateDto} usersCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreateUser: async (usersCreateDto: UsersCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersCreateDto' is not null or undefined
            assertParamExists('usersControllerCreateUser', 'usersCreateDto', usersCreateDto)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetIds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsersPatchSelfRequest} usersPatchSelfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerPatchSelf: async (usersPatchSelfRequest: UsersPatchSelfRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersPatchSelfRequest' is not null or undefined
            assertParamExists('usersControllerPatchSelf', 'usersPatchSelfRequest', usersPatchSelfRequest)
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPatchSelfRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UsersPatchRequest} usersPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerPatchUser: async (id: string, usersPatchRequest: UsersPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerPatchUser', 'id', id)
            // verify required parameter 'usersPatchRequest' is not null or undefined
            assertParamExists('usersControllerPatchUser', 'usersPatchRequest', usersPatchRequest)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsersResetPasswordRequest} usersResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword: async (usersResetPasswordRequest: UsersResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersResetPasswordRequest' is not null or undefined
            assertParamExists('usersControllerResetPassword', 'usersResetPasswordRequest', usersResetPasswordRequest)
            const localVarPath = `/users/password_reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WsAssociateRequest} wsAssociateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wsControllerAssociate: async (wsAssociateRequest: WsAssociateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wsAssociateRequest' is not null or undefined
            assertParamExists('wsControllerAssociate', 'wsAssociateRequest', wsAssociateRequest)
            const localVarPath = `/ws/associate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wsAssociateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerApprove(article: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerApprove(article, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerBatchGetArticles(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesBatchGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerBatchGetArticles(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerBatchGetComments(article: string, ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerBatchGetComments(article, ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チェック (推定+検査validate) のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesCheckRequest} articlesCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerCheck(article: string, articlesCheckRequest: ArticlesCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerCheck(article, articlesCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesCommentPutRequest} articlesCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerComment(article: string, articlesCommentPutRequest: ArticlesCommentPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerComment(article, articlesCommentPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerCreate(articlesCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerDeleteArticle(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerDeleteArticle(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerDisapprove(article: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerDisapprove(article, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetActions(article: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesActionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetActions(article, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetArticle(article: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetArticle(article, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetArticleFromSiteId(site: string, article: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetArticleFromSiteId(site, article, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetArticleVersions(article: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetArticleVersions(article, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetLatestArticle(site: string, article: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetLatestArticle(site, article, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetVersion(article: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetVersion(article, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArticlesListIdsRequest} articlesListIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerListArticleIds(articlesListIdsRequest: ArticlesListIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesListIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerListArticleIds(articlesListIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerListCommentIds(article: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerListCommentIds(article, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesNewVersionRequest} articlesNewVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerUpdateArticleAndVersion(article: string, articlesNewVersionRequest: ArticlesNewVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerUpdateArticleAndVersion(article, articlesNewVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerUpdateEstimatedValues(article: string, version: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerUpdateEstimatedValues(article, version, articlesValidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesMemoUpdateRequest} articlesMemoUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerUpdateMemo(article: string, articlesMemoUpdateRequest: ArticlesMemoUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerUpdateMemo(article, articlesMemoUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 値が法に従っているかの検査のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesValidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerValidate(article, articlesValidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerIssueAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerIssueAccessToken(authAccessTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetFeatures(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatures>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetFeatures(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetRoles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesGetRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetSelf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetSelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetSelfFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatures>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetSelfFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetSelfRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesGetRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetSelfRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdateDepartments(id: string, companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdateDepartments(id, companiesPutDepartmentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerBatchGetCsvFiles(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFilesBatchGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerBatchGetCsvFiles(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CsvFilesCreateRequest} csvFilesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerCreate(csvFilesCreateRequest: CsvFilesCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerCreate(csvFilesCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerDeleteCsvFile(csvfile: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerDeleteCsvFile(csvfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerGetCsvFile(csvfile: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerGetCsvFile(csvfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} csvIdentifier 
         * @param {CsvFilesListArticlesIdsRequest} csvFilesListArticlesIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerListCsvArticlesIds(csvIdentifier: string, csvFilesListArticlesIdsRequest: CsvFilesListArticlesIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFilesListArticlesIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerListCsvArticlesIds(csvIdentifier, csvFilesListArticlesIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CsvFilesListRequest} csvFilesListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerListCsvFileIds(csvFilesListRequest: CsvFilesListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFilesIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerListCsvFileIds(csvFilesListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {CsvFilesUpdateRequest} csvFilesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvFilesControllerUpdateCsvFile(csvfile: string, csvFilesUpdateRequest: CsvFilesUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvFilesControllerUpdateCsvFile(csvfile, csvFilesUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customsControllerGetArticleBriefDefinition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleBriefDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customsControllerGetArticleBriefDefinition(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customsControllerGetArticleDefinition(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customsControllerGetArticleDefinition(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customsControllerGetDataDefinition(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customsControllerGetDataDefinition(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customsControllerGetEstimatedValuesDefinition(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customsControllerGetEstimatedValuesDefinition(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headlessApiControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.headlessApiControllerCreate(articlesCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headlessApiControllerHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.headlessApiControllerHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headlessApiControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesValidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.headlessApiControllerValidate(article, articlesValidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsersBatchDeleteRequest} usersBatchDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerBatchDelete(usersBatchDeleteRequest: UsersBatchDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerBatchDelete(usersBatchDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerBatchGet(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersBatchGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerBatchGet(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsersCreateDto} usersCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreateUser(usersCreateDto: UsersCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreateUser(usersCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetIds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetIds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsersPatchSelfRequest} usersPatchSelfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerPatchSelf(usersPatchSelfRequest: UsersPatchSelfRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerPatchSelf(usersPatchSelfRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UsersPatchRequest} usersPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerPatchUser(id: string, usersPatchRequest: UsersPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerPatchUser(id, usersPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsersResetPasswordRequest} usersResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResetPassword(usersResetPasswordRequest: UsersResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResetPassword(usersResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WsAssociateRequest} wsAssociateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wsControllerAssociate(wsAssociateRequest: WsAssociateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wsControllerAssociate(wsAssociateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerApprove(article: string, version: string, options?: any): AxiosPromise<void> {
            return localVarFp.articlesControllerApprove(article, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerBatchGetArticles(ids: Array<string>, options?: any): AxiosPromise<ArticlesBatchGetResponse> {
            return localVarFp.articlesControllerBatchGetArticles(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerBatchGetComments(article: string, ids: Array<string>, options?: any): AxiosPromise<Array<ArticleComment>> {
            return localVarFp.articlesControllerBatchGetComments(article, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * チェック (推定+検査validate) のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesCheckRequest} articlesCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCheck(article: string, articlesCheckRequest: ArticlesCheckRequest, options?: any): AxiosPromise<ArticlesCheckResponse> {
            return localVarFp.articlesControllerCheck(article, articlesCheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesCommentPutRequest} articlesCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerComment(article: string, articlesCommentPutRequest: ArticlesCommentPutRequest, options?: any): AxiosPromise<ArticleComment> {
            return localVarFp.articlesControllerComment(article, articlesCommentPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: any): AxiosPromise<ArticlesCreateResponse> {
            return localVarFp.articlesControllerCreate(articlesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerDeleteArticle(articleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.articlesControllerDeleteArticle(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerDisapprove(article: string, version: string, options?: any): AxiosPromise<void> {
            return localVarFp.articlesControllerDisapprove(article, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetActions(article: string, version: string, options?: any): AxiosPromise<ArticlesActionsResponse> {
            return localVarFp.articlesControllerGetActions(article, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticle(article: string, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesControllerGetArticle(article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticleFromSiteId(site: string, article: string, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesControllerGetArticleFromSiteId(site, article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetArticleVersions(article: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.articlesControllerGetArticleVersions(article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} site 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetLatestArticle(site: string, article: string, options?: any): AxiosPromise<ArticleVersion> {
            return localVarFp.articlesControllerGetLatestArticle(site, article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetVersion(article: string, version: string, options?: any): AxiosPromise<ArticleVersion> {
            return localVarFp.articlesControllerGetVersion(article, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArticlesListIdsRequest} articlesListIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerListArticleIds(articlesListIdsRequest: ArticlesListIdsRequest, options?: any): AxiosPromise<ArticlesListIdsResponse> {
            return localVarFp.articlesControllerListArticleIds(articlesListIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerListCommentIds(article: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.articlesControllerListCommentIds(article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesNewVersionRequest} articlesNewVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateArticleAndVersion(article: string, articlesNewVersionRequest: ArticlesNewVersionRequest, options?: any): AxiosPromise<ArticleVersion> {
            return localVarFp.articlesControllerUpdateArticleAndVersion(article, articlesNewVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {string} version 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateEstimatedValues(article: string, version: string, articlesValidateRequest: ArticlesValidateRequest, options?: any): AxiosPromise<ArticleVersion> {
            return localVarFp.articlesControllerUpdateEstimatedValues(article, version, articlesValidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesMemoUpdateRequest} articlesMemoUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdateMemo(article: string, articlesMemoUpdateRequest: ArticlesMemoUpdateRequest, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesControllerUpdateMemo(article, articlesMemoUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 値が法に従っているかの検査のみ。保存は行わない。
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: any): AxiosPromise<ArticlesValidateResponse> {
            return localVarFp.articlesControllerValidate(article, articlesValidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthAccessTokenRequest} authAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerIssueAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: any): AxiosPromise<AuthAccessTokenResponse> {
            return localVarFp.authControllerIssueAccessToken(authAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(authLoginRequest: AuthLoginRequest, options?: any): AxiosPromise<AuthLoginResponse> {
            return localVarFp.authControllerLogin(authLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGet(id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companiesControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetFeatures(id: string, options?: any): AxiosPromise<CompanyFeatures> {
            return localVarFp.companiesControllerGetFeatures(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetRoles(id: string, options?: any): AxiosPromise<CompaniesGetRolesResponse> {
            return localVarFp.companiesControllerGetRoles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelf(options?: any): AxiosPromise<Company> {
            return localVarFp.companiesControllerGetSelf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelfFeatures(options?: any): AxiosPromise<CompanyFeatures> {
            return localVarFp.companiesControllerGetSelfFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetSelfRoles(options?: any): AxiosPromise<CompaniesGetRolesResponse> {
            return localVarFp.companiesControllerGetSelfRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateDepartments(id: string, companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.companiesControllerUpdateDepartments(id, companiesPutDepartmentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerBatchGetCsvFiles(ids: Array<string>, options?: any): AxiosPromise<CsvFilesBatchGetResponse> {
            return localVarFp.csvFilesControllerBatchGetCsvFiles(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CsvFilesCreateRequest} csvFilesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerCreate(csvFilesCreateRequest: CsvFilesCreateRequest, options?: any): AxiosPromise<CsvFile> {
            return localVarFp.csvFilesControllerCreate(csvFilesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerDeleteCsvFile(csvfile: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvFilesControllerDeleteCsvFile(csvfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerGetCsvFile(csvfile: string, options?: any): AxiosPromise<CsvFile> {
            return localVarFp.csvFilesControllerGetCsvFile(csvfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} csvIdentifier 
         * @param {CsvFilesListArticlesIdsRequest} csvFilesListArticlesIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerListCsvArticlesIds(csvIdentifier: string, csvFilesListArticlesIdsRequest: CsvFilesListArticlesIdsRequest, options?: any): AxiosPromise<CsvFilesListArticlesIdsResponse> {
            return localVarFp.csvFilesControllerListCsvArticlesIds(csvIdentifier, csvFilesListArticlesIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CsvFilesListRequest} csvFilesListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerListCsvFileIds(csvFilesListRequest: CsvFilesListRequest, options?: any): AxiosPromise<CsvFilesIdsResponse> {
            return localVarFp.csvFilesControllerListCsvFileIds(csvFilesListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} csvfile 
         * @param {CsvFilesUpdateRequest} csvFilesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvFilesControllerUpdateCsvFile(csvfile: string, csvFilesUpdateRequest: CsvFilesUpdateRequest, options?: any): AxiosPromise<CsvFile> {
            return localVarFp.csvFilesControllerUpdateCsvFile(csvfile, csvFilesUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetArticleBriefDefinition(options?: any): AxiosPromise<ArticleBriefDefinition> {
            return localVarFp.customsControllerGetArticleBriefDefinition(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetArticleDefinition(name: string, options?: any): AxiosPromise<FormDefinition> {
            return localVarFp.customsControllerGetArticleDefinition(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetDataDefinition(name: string, options?: any): AxiosPromise<DataDefinition> {
            return localVarFp.customsControllerGetDataDefinition(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customsControllerGetEstimatedValuesDefinition(name: string, options?: any): AxiosPromise<FormDefinition> {
            return localVarFp.customsControllerGetEstimatedValuesDefinition(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArticlesCreateRequest} articlesCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: any): AxiosPromise<ArticlesCreateResponse> {
            return localVarFp.headlessApiControllerCreate(articlesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerHello(options?: any): AxiosPromise<void> {
            return localVarFp.headlessApiControllerHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} article 
         * @param {ArticlesValidateRequest} articlesValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headlessApiControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: any): AxiosPromise<ArticlesValidateResponse> {
            return localVarFp.headlessApiControllerValidate(article, articlesValidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersBatchDeleteRequest} usersBatchDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerBatchDelete(usersBatchDeleteRequest: UsersBatchDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerBatchDelete(usersBatchDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerBatchGet(ids: Array<string>, options?: any): AxiosPromise<UsersBatchGetResponse> {
            return localVarFp.usersControllerBatchGet(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersCreateDto} usersCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreateUser(usersCreateDto: UsersCreateDto, options?: any): AxiosPromise<UsersModel> {
            return localVarFp.usersControllerCreateUser(usersCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetIds(options?: any): AxiosPromise<UsersIdsResponse> {
            return localVarFp.usersControllerGetIds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMe(options?: any): AxiosPromise<UsersModel> {
            return localVarFp.usersControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersPatchSelfRequest} usersPatchSelfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerPatchSelf(usersPatchSelfRequest: UsersPatchSelfRequest, options?: any): AxiosPromise<UsersModel> {
            return localVarFp.usersControllerPatchSelf(usersPatchSelfRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UsersPatchRequest} usersPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerPatchUser(id: string, usersPatchRequest: UsersPatchRequest, options?: any): AxiosPromise<UsersModel> {
            return localVarFp.usersControllerPatchUser(id, usersPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersResetPasswordRequest} usersResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword(usersResetPasswordRequest: UsersResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResetPassword(usersResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WsAssociateRequest} wsAssociateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wsControllerAssociate(wsAssociateRequest: WsAssociateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.wsControllerAssociate(wsAssociateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerApprove(article: string, version: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerApprove(article, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerBatchGetArticles(ids: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerBatchGetArticles(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerBatchGetComments(article: string, ids: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerBatchGetComments(article, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チェック (推定+検査validate) のみ。保存は行わない。
     * @param {string} article 
     * @param {ArticlesCheckRequest} articlesCheckRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerCheck(article: string, articlesCheckRequest: ArticlesCheckRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerCheck(article, articlesCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {ArticlesCommentPutRequest} articlesCommentPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerComment(article: string, articlesCommentPutRequest: ArticlesCommentPutRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerComment(article, articlesCommentPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticlesCreateRequest} articlesCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerCreate(articlesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerDeleteArticle(articleId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerDeleteArticle(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerDisapprove(article: string, version: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerDisapprove(article, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetActions(article: string, version: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetActions(article, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetArticle(article: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetArticle(article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} site 
     * @param {string} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetArticleFromSiteId(site: string, article: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetArticleFromSiteId(site, article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetArticleVersions(article: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetArticleVersions(article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} site 
     * @param {string} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetLatestArticle(site: string, article: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetLatestArticle(site, article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerGetVersion(article: string, version: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerGetVersion(article, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticlesListIdsRequest} articlesListIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerListArticleIds(articlesListIdsRequest: ArticlesListIdsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerListArticleIds(articlesListIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerListCommentIds(article: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerListCommentIds(article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {ArticlesNewVersionRequest} articlesNewVersionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerUpdateArticleAndVersion(article: string, articlesNewVersionRequest: ArticlesNewVersionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerUpdateArticleAndVersion(article, articlesNewVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {string} version 
     * @param {ArticlesValidateRequest} articlesValidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerUpdateEstimatedValues(article: string, version: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerUpdateEstimatedValues(article, version, articlesValidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {ArticlesMemoUpdateRequest} articlesMemoUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerUpdateMemo(article: string, articlesMemoUpdateRequest: ArticlesMemoUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerUpdateMemo(article, articlesMemoUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 値が法に従っているかの検査のみ。保存は行わない。
     * @param {string} article 
     * @param {ArticlesValidateRequest} articlesValidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public articlesControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).articlesControllerValidate(article, articlesValidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthAccessTokenRequest} authAccessTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerIssueAccessToken(authAccessTokenRequest: AuthAccessTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerIssueAccessToken(authAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthLoginRequest} authLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerLogin(authLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGetFeatures(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGetFeatures(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGetRoles(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGetRoles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGetSelf(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGetSelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGetSelfFeatures(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGetSelfFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerGetSelfRoles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerGetSelfRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerUpdateDepartments(id: string, companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerUpdateDepartments(id, companiesPutDepartmentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesPutDepartmentsRequest} companiesPutDepartmentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest: CompaniesPutDepartmentsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerUpdateSelfDepartments(companiesPutDepartmentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerBatchGetCsvFiles(ids: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerBatchGetCsvFiles(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvFilesCreateRequest} csvFilesCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerCreate(csvFilesCreateRequest: CsvFilesCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerCreate(csvFilesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} csvfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerDeleteCsvFile(csvfile: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerDeleteCsvFile(csvfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} csvfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerGetCsvFile(csvfile: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerGetCsvFile(csvfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} csvIdentifier 
     * @param {CsvFilesListArticlesIdsRequest} csvFilesListArticlesIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerListCsvArticlesIds(csvIdentifier: string, csvFilesListArticlesIdsRequest: CsvFilesListArticlesIdsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerListCsvArticlesIds(csvIdentifier, csvFilesListArticlesIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvFilesListRequest} csvFilesListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerListCsvFileIds(csvFilesListRequest: CsvFilesListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerListCsvFileIds(csvFilesListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} csvfile 
     * @param {CsvFilesUpdateRequest} csvFilesUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public csvFilesControllerUpdateCsvFile(csvfile: string, csvFilesUpdateRequest: CsvFilesUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).csvFilesControllerUpdateCsvFile(csvfile, csvFilesUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customsControllerGetArticleBriefDefinition(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customsControllerGetArticleBriefDefinition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customsControllerGetArticleDefinition(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customsControllerGetArticleDefinition(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customsControllerGetDataDefinition(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customsControllerGetDataDefinition(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customsControllerGetEstimatedValuesDefinition(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customsControllerGetEstimatedValuesDefinition(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticlesCreateRequest} articlesCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public headlessApiControllerCreate(articlesCreateRequest: ArticlesCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).headlessApiControllerCreate(articlesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public headlessApiControllerHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).headlessApiControllerHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} article 
     * @param {ArticlesValidateRequest} articlesValidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public headlessApiControllerValidate(article: string, articlesValidateRequest: ArticlesValidateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).headlessApiControllerValidate(article, articlesValidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersBatchDeleteRequest} usersBatchDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerBatchDelete(usersBatchDeleteRequest: UsersBatchDeleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerBatchDelete(usersBatchDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerBatchGet(ids: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerBatchGet(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersCreateDto} usersCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerCreateUser(usersCreateDto: UsersCreateDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerCreateUser(usersCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerGetIds(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerGetIds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerMe(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersPatchSelfRequest} usersPatchSelfRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerPatchSelf(usersPatchSelfRequest: UsersPatchSelfRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerPatchSelf(usersPatchSelfRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UsersPatchRequest} usersPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerPatchUser(id: string, usersPatchRequest: UsersPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerPatchUser(id, usersPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersResetPasswordRequest} usersResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerResetPassword(usersResetPasswordRequest: UsersResetPasswordRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerResetPassword(usersResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WsAssociateRequest} wsAssociateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public wsControllerAssociate(wsAssociateRequest: WsAssociateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).wsControllerAssociate(wsAssociateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


