import React, { Suspense, VFC } from "react";
import { useRecoilValue } from "recoil";

import { companySelector } from "../../api/company.selector";

const CompanyNameInner: VFC = () => {
  const company = useRecoilValue(companySelector);
  return <>{company?.name}</>;
};

export const CompanyName: VFC = () => {
  return (
    <Suspense fallback="読み込み中">
      <CompanyNameInner />
    </Suspense>
  );
};
