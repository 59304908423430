import React, { useEffect } from "react";

import styles from "./UnauthorizedLayout.module.css";

export const UnauthorizedLayout: React.FC = (props) => {
  const { children } = props;

  useEffect(() => {
    if (localStorage.getItem("logged_out")) {
      window.alert("ログアウトされました");
      localStorage.removeItem("logged_out");
    }
  }, []);

  return <div className={styles.wrapper}>{children}</div>;
};
