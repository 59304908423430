import { VFC } from "react";

import { useAccessTokenReissuerImpl } from "../components/auth/auth.atom";

/* eslint "react-hooks/rules-of-hooks": "error", "react-hooks/exhaustive-deps": "error" */
export const AuthTokenReissuer: VFC = () => {
  useAccessTokenReissuerImpl();

  return null;
};
