import React, { useEffect, useRef } from "react";
import { atom, useRecoilState } from "recoil";

export const intervalAtom = atom<Date>({
  key: "interval",
  default: new Date(),
});

export const useRecoilInterval = () => {
  const [now, setNow] = useRecoilState(intervalAtom);
  const ref = useRef(now);
  useEffect(() => {
    ref.current = now;
  }, [now]);

  useEffect(() => {
    const id = setInterval(() => {
      // console.log(ref.current);
      if (new Date().getTime() - ref.current.getTime() > 2000) {
        setNow(new Date());
      }
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);
  return now;
};

export const RecoilInterval = () => {
  useRecoilInterval();
  return <></>;
};
