import EventEmitter from "eventemitter3";
import { useAsync } from "react-use";
import useWebSocket from "react-use-websocket";

import { useApiWithAuth } from "./apit";

export const websocketBasePath = (() => {
  /* eslint-disable no-restricted-globals*/
  if (location.hostname === "localhost") {
    return `wss://ws.dev.shepherdhr.jp`;
  }
  return `wss://ws.${location.host}`;
  /* eslint-enable no-restricted-globals*/
})();

export const WebsocketSubscriber: React.FC = () => {
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(websocketBasePath, {
    shouldReconnect: () => true,
  });
  const api = useApiWithAuth();
  useAsync(async () => {
    if (sendJsonMessage) {
      sendJsonMessage({ action: "token" });
    }
  }, [api, sendJsonMessage]);

  useAsync(async () => {
    const { action } = lastJsonMessage;
    if (action === "welcome") {
      await api.wsControllerAssociate({
        connection_id: lastJsonMessage.connection_id,
        token: lastJsonMessage.token,
      });
      console.log("associated ws connection with the user");
    }
    if (action === "article_comment_created") {
      const articleIdentifier: string = lastJsonMessage.article_identifier;
      wsNotificationEmitter.emit(
        `article_comment_created:${articleIdentifier}`,
        lastJsonMessage
      );
    } else if (action === "article_reviewer_changed") {
      wsNotificationEmitter.emit(action, lastJsonMessage);
    } else if (action === "article_check_status_changed") {
      wsNotificationEmitter.emit(action, lastJsonMessage);
    } else if (action === "csv_file_upload_status_changed") {
      wsNotificationEmitter.emit(action, lastJsonMessage);
    }
  }, [api, lastJsonMessage]);
  return <></>;
};
type ArticleCommentCreatedEvent = `article_comment_created:${string}`;

export interface SpecificWebsocketEventTypes {
  article_reviewer_changed: [{ article_identifier: string; version: string }];
  article_check_status_changed: [
    { article_identifier: string; version: string }
  ];
  csv_file_upload_status_changed: [{ csv_identifier: string }];
}

export type WebsocketEventTypes = SpecificWebsocketEventTypes & {
  [k in ArticleCommentCreatedEvent]: unknown;
};

export const wsNotificationEmitter = new EventEmitter<
  WebsocketEventTypes,
  never
>();
