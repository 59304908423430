import clsx from "clsx";
import React from "react";

import buttonStyles from "./Button.module.css";

export const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, type, ...rest } = props;
  return (
    <button
      {...rest}
      type={type ?? "button"}
      className={clsx(buttonStyles.button, className)}
    >
      {children}
    </button>
  );
};

export const PrimaryButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button
      {...rest}
      className={clsx(buttonStyles["primary-button"], className)}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button
      {...rest}
      className={clsx(buttonStyles["secondary-button"], className)}
    >
      {children}
    </Button>
  );
};

export const LightButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={clsx(buttonStyles["light-button"], className)}>
      {children}
    </Button>
  );
};

export const GrayButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={clsx(buttonStyles["gray-button"], className)}>
      {children}
    </Button>
  );
};

export const OutlinedButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button
      {...rest}
      className={clsx(buttonStyles["outlined-button"], className)}
    >
      {children}
    </Button>
  );
};

export const TextButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={clsx(buttonStyles["text-button"], className)}>
      {children}
    </Button>
  );
};
