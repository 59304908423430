import { useEffect, useMemo } from "react";
import Spinner from "react-spinkit";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { AsyncReturnType } from "type-fest";

import { localKey } from "./key";

export const NetoworkActivityKey = "network_activity";

const atoms = atomFamily<number, string>({
  key: localKey("activity", "indicator"),
  default: () => 0,
});

export const ActivityIndicatorPresentator: React.FC<{ name: string }> = ({
  name,
}) => {
  const count = useRecoilValue(atoms(name));

  return count > 0 ? (
    <div
      style={{
        display: "flex",
        backgroundColor: "rgba(0,0,0,0.4)",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000,
        content: "",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner name="ball-spin-fade-loader" fadeIn="none" color="gray" />
    </div>
  ) : null;
};

export const ActivityIndicatorRequest: React.FC<{ name: string }> = ({
  name,
}) => {
  const setCount = useSetRecoilState(atoms(name));
  useEffect(() => {
    setCount((v) => v + 1);
    return () => {
      setCount((v) => v - 1);
    };
  }, [setCount]);
  return <></>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFunction = (...args: any[]) => Promise<any>;

export const useActivityIndicatorAsyncWrapper = (name: string) => {
  const setCount = useSetRecoilState(atoms(name));
  const wrap = useMemo(
    () =>
      function wrap<F extends AsyncFunction>(f: F, delayDisappear?: number) {
        type P = Parameters<F>;
        return async function wrapper(...args: P): Promise<AsyncReturnType<F>> {
          let result;
          try {
            setCount((v) => v + 1);
            result = await f(...args);
          } catch (e) {
            setTimeout(() => {
              setCount((v) => v - 1);
            }, delayDisappear ?? 0);
            throw e;
          }
          setTimeout(() => {
            setCount((v) => v - 1);
          }, delayDisappear ?? 0);
          return result as AsyncReturnType<F>;
        };
      },
    [setCount]
  );
  return wrap;
};
