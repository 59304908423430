import { AxiosRequestConfig } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useCallback, useEffect, useRef } from "react";
import {
  atom,
  DefaultValue,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { Configuration, DefaultApiFactory } from "../../generated-api-client";
import { apiBasePath } from "../api/api-base-path";
import { apiKeyAtom } from "../api/apiKey.atom";
import { useApiWithoutAuth } from "../api/apit";
import { globalKey } from "../util/key";

export interface TokenPayload extends JwtPayload {
  user: {
    user_id: string;
    company_id: string;
    role: string;
    admin: boolean;
  };
}

type RefreshToken = { type: "session" | "long_lived"; token: string };

function getRefreshTokenFromStorage(): RefreshToken | undefined {
  const longLivedToken = localStorage.getItem("refresh_token");
  if (longLivedToken) {
    console.log({ longLivedToken });
    return { type: "long_lived", token: longLivedToken };
  }
  const sessionToken = sessionStorage.getItem("refresh_token");
  if (sessionToken) {
    console.log({ sessionToken });
    return { type: "session", token: sessionToken };
  }
  return undefined;
}

// MEMO: エクスポート禁止。間違ってサブスクライブすると不用意に画面更新が走る
const refreshTokenAtom = atom<RefreshToken | undefined>({
  key: globalKey("refresh_token"),
  default: getRefreshTokenFromStorage(),
});

// MEMO: エクスポート禁止。間違ってサブスクライブすると不用意に画面更新が走る
const refreshTokenSelector = selector<RefreshToken | undefined>({
  key: globalKey("refresh_token_selector"),
  get({ get }) {
    console.log(get(refreshTokenAtom));
    return get(refreshTokenAtom);
  },
  set: ({ set }, newValue) => {
    console.log(newValue);
    if (newValue instanceof DefaultValue) {
      set(refreshTokenAtom, getRefreshTokenFromStorage());
      return;
    }
    if (!newValue) {
      localStorage.removeItem("refresh_token");
      sessionStorage.removeItem("refresh_token");
    } else if (newValue.type === "session") {
      sessionStorage.setItem("refresh_token", newValue.token);
    } else {
      localStorage.setItem("refresh_token", newValue.token);
    }
    set(refreshTokenAtom, newValue);
  },

  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

// MEMO: エクスポート禁止。間違ってサブスクライブすると不用意に画面更新が走る
const accessTokenAtom = atom<undefined | string>({
  key: globalKey("access_token"),
  default: undefined,
});

// MEMO: エクスポート禁止。間違ってサブスクライブすると不用意に画面更新が走る
// 描画を遅延するにはReact Suspenseを使うのが速いが、なんかうまく行かなかった。
// 初回のみselectorで描画を遅延させ、通常はaccessTokenAtomにアクセストークンを保存する。
const accessTokenSelector = selector<undefined | string>({
  key: globalKey("access_token_selector"),
  get: async ({ get }) => {
    const currentAccessToken = get(accessTokenAtom);
    if (currentAccessToken) {
      return currentAccessToken;
    }
    const apiKey = get(apiKeyAtom);
    const refreshToken = get(refreshTokenSelector);
    if (refreshToken) {
      try {
        const issueResult = await DefaultApiFactory(
          new Configuration({
            baseOptions: { headers: { "X-Api-Key": apiKey ?? "" } },
          }),
          apiBasePath
        ).authControllerIssueAccessToken({ refresh_token: refreshToken.token });
        return issueResult.data.access_token;
      } catch (e) {
        console.error(e);
      }
    }
    return undefined;
  },
  set: ({ set }, newValue) => {
    console.log({ newValue });
    set(accessTokenAtom, newValue);
  },

  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

// MEMO: エクスポート禁止。間違ってサブスクライブすると不用意に画面更新が走る
const decodedAccessTokenSelector = selector<undefined | TokenPayload>({
  key: globalKey("decoded_access_token"),
  get: async ({ get }) => {
    const access_token = get(accessTokenSelector);
    // console.log("decode", access_token);
    return access_token !== undefined
      ? jwtDecode<TokenPayload>(access_token)
      : undefined;
  },

  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export const accessTokenIatSelector = selector<string>({
  key: globalKey("access_token_issued_at"),
  get: ({ get }) => {
    const decoded = get(decodedAccessTokenSelector);
    return new Date(decoded?.iat ? decoded.iat * 1000 : "").toLocaleString();
  },
});

export const loggedInUserSelector = selector<TokenPayload["user"] | undefined>({
  key: globalKey("logged_in_user"),
  get: ({ get }) => {
    const decoded = get(decodedAccessTokenSelector);
    // console.log("decoded", decoded);
    if (decoded?.user) {
      return decoded.user;
    }
    return undefined;
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export const loggedInUserIdSelector = selector<string | undefined>({
  key: globalKey("logged_in_user_id"),
  get: ({ get }) => {
    const decoded = get(decodedAccessTokenSelector);
    if (decoded?.user) {
      return decoded.user.user_id;
    }
    return undefined;
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export const accessTokenResolverForCurrentUser = selector<
  () => string | undefined
>({
  key: globalKey("access_token_getter"),
  get: ({ get, getCallback }) => {
    const user_id = get(loggedInUserIdSelector);
    console.log({ user_id });
    if (user_id) {
      return getCallback(({ snapshot }) => {
        return () => snapshot.getLoadable(accessTokenSelector).getValue();
      });
    }
    return () => undefined;
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export const useAccessTokenReissuerImpl = () => {
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenSelector);
  const setAccessToken = useSetRecoilState(accessTokenSelector);
  const decodedAccessToken = useRecoilValue(decodedAccessTokenSelector);
  const api = useApiWithoutAuth();
  const lock = useRef(false);

  useEffect(() => {
    const timer = setInterval(async () => {
      // console.log({ refreshToken, decodedAccessToken, lock: lock.current });
      if (!decodedAccessToken?.exp || lock.current) {
        return;
      }
      lock.current = true;
      try {
        const accessTokenRemainSeconds =
          decodedAccessToken.exp - Date.now() / 1000;
        if (accessTokenRemainSeconds < 60) {
          try {
            if (refreshToken?.token) {
              const result = await api.authControllerIssueAccessToken({
                refresh_token: refreshToken.token,
              });
              setAccessToken(result.data.access_token);
            }
          } catch (e) {
            console.error(e);
            setRefreshToken(undefined);
          }
        }
      } finally {
        lock.current = false;
      }
    }, 5000);

    return () => clearInterval(timer);
  }, [api, decodedAccessToken, refreshToken, setAccessToken, setRefreshToken]);
};

export const useAuthActions: () => [
  (
    connectionKey: string,
    email: string,
    password: string,
    longLived: boolean
  ) => Promise<void>,
  () => void
] = () => {
  const set_refresh_token = useSetRecoilState(refreshTokenSelector);
  const set_access_token = useSetRecoilState(accessTokenSelector);
  const set_api_key = useSetRecoilState(apiKeyAtom);
  const api = useApiWithoutAuth();

  const login = useCallback(
    async (
      apiKey: string,
      email: string,
      password: string,
      long_lived: boolean
    ) => {
      const options: AxiosRequestConfig = {
        headers: {
          "X-Api-Key": apiKey,
        },
      };
      const result = await api.authControllerLogin(
        {
          email,
          password,
          long_lived,
        },
        options
      );

      localStorage.removeItem("last_logged_out_at");
      localStorage.removeItem("logged_out");
      set_api_key(apiKey);
      set_refresh_token({
        type: long_lived ? "long_lived" : "session",
        token: result.data.refresh_token,
      });
      set_access_token(result.data.access_token);
    },
    [api, set_access_token, set_api_key, set_refresh_token]
  );

  const logout = () => {
    localStorage.setItem("last_logged_out_at", new Date().toLocaleString());
    set_refresh_token(undefined);
    set_access_token(undefined);
  };

  return [login, logout];
};
