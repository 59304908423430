import { DefaultValue, selector } from "recoil";

import { Company } from "../../generated-api-client";
import { globalKey } from "../util/key";
import { apiClientSelector } from "./apit";

export const companySelector = selector<Company | undefined>({
  key: globalKey("company_selector"),
  get: async ({ get }) => {
    const api = get(apiClientSelector);
    try {
      const response = await api.companiesControllerGetSelf();
      return response.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
  set: (_, value) => {
    console.log({ value });
    if (!(value instanceof DefaultValue)) {
      throw new Error("should not be called");
    }
  },
});
