import clsx from "clsx";
import React, { useEffect, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { WebsocketSubscriber } from "../../components/api/websocket";
import {
  loggedInUserSelector,
  useAuthActions,
} from "../../components/auth/auth.atom";
import { RecoilInterval } from "../../components/util/useRecoileInterval";
import { TextButton } from "../../components/view/Button";
import { CompanyName } from "../../components/view/layout/CompanyName";
import styles from "./AuthorizedLayout.module.css";

export const AuthorizedLayout: React.FC = (props) => {
  const { children } = props;
  const [, logout] = useAuthActions();
  const logged_in_user = useRecoilValue(loggedInUserSelector);
  const history = useHistory();
  useEffect(() => {
    console.log("logged_in_user", logged_in_user);
    const loggedOut = localStorage.getItem("logged_out");
    const lastLoggedOutAt = localStorage.getItem("last_logged_out_at");
    if (!logged_in_user || loggedOut || lastLoggedOutAt) {
      window.alert("ログインが必要です");
      history.push("/");
    }
  }, [history, logged_in_user]);
  const location = useLocation();

  const selected_route:
    | "articles"
    | "extension"
    | "file_check"
    | "company"
    | "account"
    | "other" = useMemo(() => {
    if (location.pathname.match(/^\/articles/)) {
      return "articles";
    }
    if (location.pathname.match(/^\/extension/)) {
      return "extension";
    }
    if (location.pathname.match(/^\/file_check/)) {
      return "file_check";
    }
    if (location.pathname.match(/^\/company/)) {
      return "company";
    }
    if (location.pathname.match(/^\/account/)) {
      return "account";
    }
    return "other";
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <nav className={styles.sidebar}>
        <div className={styles.company_name}>
          <CompanyName />
        </div>
        <ul>
          <li
            className={clsx(selected_route === "articles" && styles.selected)}
          >
            <Link to="/articles" className={styles.history}>
              求人チェック履歴
            </Link>
          </li>
          {/* <li
            className={clsx(selected_route === "extension" && styles.selected)}
          >
            <Link to="/extension" className={styles.extension}>
              Chrome拡張設定
            </Link>
          </li> */}
          <li
            className={clsx(selected_route === "file_check" && styles.selected)}
          >
            <Link to="/file_check" className={styles.file_check}>
              ファイルチェック
            </Link>
          </li>
          {/* <li>
            <Link to="/rule_settings" className={styles.rule_settings}>
              ルール設定
            </Link>
          </li> */}
          {logged_in_user?.admin && (
            <li
              className={clsx(selected_route === "company" && styles.selected)}
            >
              <Link to="/company/members" className={styles.organization}>
                ユーザー管理
              </Link>
            </li>
          )}
          <li className={clsx(selected_route === "account" && styles.selected)}>
            <Link to="/account/settings" className={styles.account_settings}>
              アカウント設定
            </Link>
          </li>
        </ul>
        <div className={styles.logout}>
          <TextButton
            onClick={() => {
              logout();
              history.push("/");
            }}
          >
            ログアウト
          </TextButton>
        </div>
        <div className={styles.service_name}>シェパードHR</div>
      </nav>
      <div className={styles.content}>{children}</div>
      <RecoilInterval />
      <WebsocketSubscriber />
    </div>
  );
};
