import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

import "./App.css";
import {
  ActivityIndicatorPresentator,
  NetoworkActivityKey,
} from "./components/util/ActivityIndicator";
import { AuthTokenReissuer } from "./pages/AuthTokenReissuer";
import { AuthorizedLayout } from "./pages/layouts/AuthorizedLayout";
import { UnauthorizedLayout } from "./pages/layouts/UnauthorizedLayout";

const AboutPage = React.lazy(() => import("./pages/About"));
const ExamlplePage = React.lazy(() => import("./pages/Example"));
const HomePage = React.lazy(() => import("./pages/Home"));
const PasswordResetPage = React.lazy(() => import("./pages/PasswordReset"));
const PasswordChangePage = React.lazy(() => import("./pages/PasswordChange"));
const ArticlesListPage = React.lazy(() => import("./pages/ArticlesList"));
const ExtensionPage = React.lazy(() => import("./pages/Extension"));
const FileCheckPage = React.lazy(() => import("./pages/FileCheck"));
const FileCheckArticleListPage = React.lazy(
  () => import("./pages/FileCheckArticleList")
);
const CompanyMembersPage = React.lazy(() => import("./pages/CompanyMembers"));
const AccountSettingsPage = React.lazy(() => import("./pages/AccountSettings"));

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <AuthTokenReissuer />
          <ToastContainer hideProgressBar />
          <Switch>
            <Route exact path={["/", "/auth/*", "/about", "/example"]}>
              <UnauthorizedLayout>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route
                    path="/auth/password_reset"
                    component={PasswordResetPage}
                  />
                  <Route
                    path="/auth/password_change"
                    component={PasswordChangePage}
                  />
                </Switch>
                <Route path="/about" component={AboutPage} />
                <Route path="/example" component={ExamlplePage} />
              </UnauthorizedLayout>
            </Route>
            <Route>
              <AuthorizedLayout>
                <Switch>
                  <Route path="/articles" component={ArticlesListPage} />
                  <Route path="/extension" component={ExtensionPage} />
                  <Route
                    path="/file_check"
                    render={() => (
                      <Switch>
                        <Route
                          exact
                          path="/file_check"
                          component={FileCheckPage}
                        />
                        <Route
                          path="/file_check/:csv_identifier"
                          component={FileCheckArticleListPage}
                        />
                      </Switch>
                    )}
                  />
                  <Route
                    path="/company/members"
                    component={CompanyMembersPage}
                  />
                  <Route
                    path="/account/settings"
                    component={AccountSettingsPage}
                  />
                </Switch>
              </AuthorizedLayout>
            </Route>
          </Switch>
        </Suspense>
      </Router>
      <ActivityIndicatorPresentator name={NetoworkActivityKey} />
    </RecoilRoot>
  );
};

export default App;
